.App {
  text-align: center;
  width: 100%;
  max-width: 1350px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Pict {
  width: 100%;
}

.Footer {
  background-color: #505050;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding в % всегда измеряется от высоты, а не от ширины*/
  padding: 4%;
}

.AppIconLink {
  width: 20%;
  max-width: 20%;
  height: auto;
}
.AppIcon {
  width: 20%;
  max-width: 20%;
  height: auto;
  background-color: white;
  border-radius: 16%;
}
.AppIconLink .AppIcon {
  width: 100%;
  max-width: 100%;
}
.Badge {
  margin: 0 4%;
  max-width: 56%; /* 100 - 2*20 - 4*/
  max-height: 40px;
}

.DesktopCenter {
  width: 100%;
  position: relative;
}

.DesktopLink {
  display: block;
  position: absolute;
  width: 30%;
  top: 0;
  bottom: 0;
  background: transparent;
  opacity: 0;
}
